body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.container {
  max-width: 800px;
}

iframe {
  width: 100%; /* Responsive width */
  max-width: 800px; /* Maximum width constraint */
  aspect-ratio: 1 / 1; /* Ensures it remains square */
  height: auto; /* Automatically adjusts height based on aspect ratio */
  border: none;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.icon {
  font-size: 2rem;
  color: #fff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
  transform: scale(1.2);
}

/* Platform-Specific Colors */
.spotify:hover {
  color: #1db954; /* Spotify Green */
}

.apple:hover {
  color: #fa243c; /* Apple Music Red */
}

.youtube:hover {
  color: #ff0000; /* YouTube Red */
}

.soundcloud:hover {
  color: #ff8800; /* SoundCloud Orange */
}

.email:hover {
  color: #0072c6; /* Email Blue */
}

.tiktok:hover {
  color: #ff0050; /* TikTok Pinkish Red */
}

.twitch:hover {
  color: #9146ff; /* Twitch Purple */
}

.instagram:hover {
  color: #e1306c; /* Instagram Gradient Pink */
}

/* Sticky Text */
.sticky-text {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0; /* Ensure the container spans across the width of the App */
  display: flex;
  justify-content: space-between;
  padding: 0 10px; /* Add some horizontal padding */
  pointer-events: none; /* Prevent interference with clickable elements below */
}

.top-left {
  font-family: "Courier New", Courier, monospace; /* Typewriter-like font */
  color: #fff;
  font-size: 1rem;
}

.top-right {
  font-family: "Courier New", Courier, monospace;
  color: #fff;
  font-size: 1rem;
  text-align: right;
}

